import React, { Component } from 'react';
import './tokens.css';
import moment from 'moment';
import * as translationUtils from '../../../common-utils/translation_utils';
import CreateToken from './CreateToken';
import getUserTokenList, {
  createUserToken,
  refreshUserToken,
  deactivateUserToken,
} from '../../../services/getTokenData';
import TokenListItem from './TokenListItem';
import ConfirmationPopUp from './ConfirmationPopUp';
import PopUpComponent from '../PopUpComponent';

class UserTokens extends Component {
  $ = window.jQuery;
  constructor(props) {
    super(props);
    this.state = {
      showToken: [],
      copyToken: [],
      userTokenList: [],
      tokenName: '',
      tokenNameError: false,
      selectedToken: null,
      selectedAction: null,
      status: '',
      message: '',
    };

    this.handleTokenView = this.handleTokenView.bind(this);
    this.handleTokenCopy = this.handleTokenCopy.bind(this);
    this.handleCreateToken = this.handleCreateToken.bind(this);
    this.handleTokenName = this.handleTokenName.bind(this);
    this.handleTokenAction = this.handleTokenAction.bind(this);
    this.handleRefreshToken = this.handleRefreshToken.bind(this);
    this.handleDeactivateToken = this.handleDeactivateToken.bind(this);
  }

  handleTokenView(num) {
    this.setState((prevState) => {
      const newItems = [...prevState.showToken].map((item) => false);
      newItems[num] = !newItems[num];
      return { showToken: newItems };
    });
  }

  handleTokenCopy(num, token) {
    navigator.clipboard.writeText(token);
    this.setState((prevState) => {
      const newItems = [...prevState.copyToken].map((item) => false);
      newItems[num] = !newItems[num];
      return { copyToken: newItems };
    });
  }

  setUserTokenList(data) {
    this.setState({
      userTokenList: data,
    });
  }

  setTokenState(count) {
    this.setState({
      showToken: new Array(count).fill(false),
    });

    this.setState({
      copyToken: new Array(count).fill(false),
    });
  }

  setStatusMessage(status, message) {
    this.setState({
      status: status,
      message: message,
    });
  }

  componentDidMount() {
    getUserTokenList(this, window.sessionStorage.getItem('oktaUserMeId'));
    //getUserTokenList(this, '1022e0Lpe5vQG6AmCmpGMcmug');
  }

  componentDidUpdate() {
    //console.log("I am updated");
  }

  handleCreateToken() {
    if (this.state.tokenName == '') {
      this.setState({ tokenNameError: true });
    } else {
      this.props.getLoader(true);
      const userId = window.sessionStorage.getItem('oktaUserMeId');
      //const sessionId = '1020dGhgO5LShKBjZ5qUHIg_w'
      createUserToken(this, userId, this.state.tokenName);
      this.setState({ tokenName: '' });
    }
  }

  handleTokenName(e) {
    this.setState({ tokenNameError: false });
    const currentValue = e.target.value;
    this.setState({
      tokenName: currentValue,
    });
  }

  handleTokenAction(tokenItem, action) {
    this.setState({ selectedToken: tokenItem });
    this.setState({ selectedAction: action });
  }

  handleRefreshToken() {
    this.props.getLoader(true);
    const userId = window.sessionStorage.getItem('oktaUserMeId');
    //const sessionId = '1020dGhgO5LShKBjZ5qUHIg_w'
    refreshUserToken(this, userId, this.state.selectedToken.Token);
  }

  handleDeactivateToken() {
    this.props.getLoader(true);
    const userId = window.sessionStorage.getItem('oktaUserMeId');
    //const sessionId = '1020dGhgO5LShKBjZ5qUHIg_w'
    deactivateUserToken(this, userId, this.state.selectedToken.Token);
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="ph-header-main__title row px-md-4 px-lg-3">
            <div className="col">
              <h1 className="p-title">
                {translationUtils.getTranslation(3343, 'Tokens')}
              </h1>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="ph-header-main__breadcrumbs row px-md-4 px-lg-3 py-2 ">
            <div className="col">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item" aria-current="page">
                    <a href={'/'}>
                      {translationUtils.getTranslation(791, 'Home')}
                    </a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {translationUtils.getTranslation(3343, 'Tokens')}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {this.state.message != '' && this.state.message != undefined && (
          <PopUpComponent
            status={this.state.status}
            message={this.state.message}
          />
        )}
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="header-details pt-4 pb-4">
                {`${translationUtils.getTranslation(
                  198,
                  'All'
                )} ${translationUtils.getTranslation(3343, 'Tokens')}`}
                <button
                  style={{ float: 'right' }}
                  type="button"
                  className="button primary-button round-button medium-button ml-1"
                  data-toggle="modal"
                  data-target="#createTokenModal"
                >
                  {`${translationUtils.getTranslation(
                    1350,
                    'Create'
                  )} ${translationUtils.getTranslation(3342, 'Token')}`}
                </button>
                <CreateToken
                  tokenNameError={this.state.tokenNameError}
                  tokenName={this.state.tokenName}
                  handleCreateToken={this.handleCreateToken}
                  handleTokenName={this.handleTokenName}
                />
              </div>
              <table id="js-ph-datatable" style={{ width: '100%' }}>
                <thead>
                  <tr
                    className={
                      'ph-flex-table__header d-flex flex-column flex-sm-row justify-content-around'
                    }
                  >
                    <th>
                      <p>{`${translationUtils.getTranslation(
                        3342,
                        'Token'
                      )} ${translationUtils.getTranslation(355, 'Name')}`}</p>
                    </th>
                    <th>
                      <p>{`${translationUtils.getTranslation(
                        3352,
                        'Created At'
                      )}`}</p>
                    </th>
                    <th>
                      <p>{`${translationUtils.getTranslation(
                        3353,
                        'Expires'
                      )}`}</p>
                    </th>
                    <th>
                      <p>{`${translationUtils.getTranslation(
                        3354,
                        'Last Used'
                      )}`}</p>
                    </th>
                    <th>
                      <p>{`${translationUtils.getTranslation(
                        1064,
                        'Actions'
                      )}`}</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.userTokenList &&
                  this.state.userTokenList.length > 0 ? (
                    this.state.userTokenList.map((tokenItem, index) => (
                      <>
                        <TokenListItem
                          key={index}
                          tokenItem={tokenItem}
                          index={index}
                          showToken={this.state.showToken}
                          copyToken={this.state.copyToken}
                          handleTokenView={this.handleTokenView}
                          handleTokenCopy={this.handleTokenCopy}
                          handleTokenAction={this.handleTokenAction}
                        />
                        <ConfirmationPopUp
                          action={this.state.selectedAction}
                          token={this.state.selectedToken}
                          handleRefreshToken={this.handleRefreshToken}
                          handleDeactivateToken={this.handleDeactivateToken}
                        />
                      </>
                    ))
                  ) : (
                    <tr
                      className={
                        'ph-flex-table__row row-first-part d-flex flex-column flex-sm-row justify-content-around'
                      }
                    >
                      <td colSpan="5">{`${translationUtils.getTranslation(
                        2090,
                        'No records found'
                      )}`}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserTokens;
