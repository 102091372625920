var CLIENT_ID;
var ISSUER;
var authorizeURL;
const urlParams = new URLSearchParams(window.location.search);
let cid = urlParams.get("cid");
cid = cid != undefined ? cid : "";
let langCode = "EN";
const urlObj = new URL(window.location.href);
const pathSegments = urlObj?.pathname?.split('/');
if(pathSegments?.length > 0){
    langCode = pathSegments[1];
  }

if (
  window.location.origin.indexOf("cor089xa270") > 0 ||
  window.location.origin.indexOf("local") > 0 ||
  window.location.origin.indexOf("myparkerdev") > 0
) {
  CLIENT_ID = process.env.CLIENT_ID || "0oawjrzcs2wV3D67D0h7";
  ISSUER = process.env.ISSUER || "https://logintest.parker.com";
  authorizeURL = window.location.href.includes("myparker/search")
    ? `https://mploginqa.parker.com/?reqContext=https://myparkerdev.parker.com/${langCode}/myparker/search?${encodeURIComponent(urlParams.toString())}`
    : cid && cid.length > 1
    ? `https://mploginqa.parker.com/?reqContext=https://myparkerdev.parker.com/EN/myparker/authenticatedUser?cid=${cid}`
    : `https://mploginqa.parker.com/?reqContext=https://myparkerdev.parker.com`;
    if (
      window.location.origin.indexOf("cor089xa270") > 0 ||
      window.location.origin.indexOf("local") > 0
    ) {
      CLIENT_ID = process.env.CLIENT_ID || "0oayb7v004CqELt870h7";
      ISSUER = process.env.ISSUER || "https://loginqa.parker.com";
      authorizeURL = window.location.href.includes("myparker/search")
        ? `https://mploginqa.parker.com/?reqContext=http://localhost:8080/EN/myparker/search?${encodeURIComponent(urlParams.toString())}`
        : cid && cid.length > 1
        ? `https://mploginqa.parker.com/?reqContext=http://localhost:8080/EN/myparker/authenticatedUser?cid=all`
        : `https://mploginqa.parker.com/?reqContext=http://localhost:8080/EN/myparker/authenticatedUser`;
    }
} else if (window.location.origin.indexOf("myparkertest") > 0) {
  CLIENT_ID = process.env.CLIENT_ID || "0oayb7v004CqELt870h7";
  ISSUER = process.env.ISSUER || "https://loginqa.parker.com";
  authorizeURL = window.location.href.includes("myparker/search")
    ? `https://mploginqa.parker.com/?reqContext=https://myparkertest.parker.com/${langCode}/myparker/search?${encodeURIComponent(urlParams.toString())}`
    : cid && cid.length > 1
    ? `https://mploginqa.parker.com/?reqContext=https://myparkertest.parker.com/EN/myparker/authenticatedUser?cid=${cid}`
    : `https://mploginqa.parker.com/?reqContext=https://myparkertest.parker.com`;
} else if (window.location.origin.indexOf("myparkerstage") > 0) {
  CLIENT_ID = process.env.CLIENT_ID || "0oayb830i4agNsp8E0h7";
  ISSUER = process.env.ISSUER || "https://loginstage.parker.com";
  authorizeURL = window.location.href.includes("myparker/search")
    ? `https://mploginstage.parker.com/?reqContext=https://myparkerstage.parker.com/${langCode}/myparker/search?${encodeURIComponent(urlParams.toString())}`
    : cid && cid.length > 1
    ? `https://mploginstage.parker.com/?reqContext=https://myparkerstage.parker.com/EN/myparker/authenticatedUser?cid=${cid}`
    : `https://mploginstage.parker.com/?reqContext=https://myparkerstage.parker.com`;
} else if (window.location.origin.indexOf("myparker") > 0) {
  CLIENT_ID = process.env.CLIENT_ID || "0oaamk7bq3h6v2cvh2p7";
  ISSUER = process.env.ISSUER || "https://login.parker.com";
  authorizeURL = window.location.href.includes("myparker/search")
    ? `https://mplogin.parker.com/?reqContext=https://myparker.parker.com/${langCode}/myparker/search?${encodeURIComponent(urlParams.toString())}`
    : cid && cid.length > 1
    ? `https://mplogin.parker.com/?reqContext=https://myparker.parker.com/EN/myparker/authenticatedUser?cid=${cid}`
    : `https://mplogin.parker.com/?reqContext=https://myparker.parker.com`;
}
if (
  window.location.origin.indexOf("cor089xa270") > 0 ||
  window.location.origin.indexOf("local") > 0
) {
  var redirectURI = "http://localhost:8080/implicit/callback";
  var messageURL = "http://localhost:8080/api/messages";
} else {
  var redirectURI = `${window.location.origin}/implicit/callback`;
  var messageURL = `${window.location.origin}/api/messages`;
}

const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: redirectURI,
    scopes: ["openid", "profile", "email"],
    pkce: false,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
      // onAuthRequired: () => {
      //   window.location = authorizeURL;
      // },
  },
  resourceServer: {
    messagesUrl: messageURL,
  },
};
