import React,{ useState, useEffect } from "react";
// import smtp from 'smtp.js';
// import emailjs from '@emailjs/browser';
import { getAPIAuthKey, getNewDomain, getHostForDA, getEnvForSearch, getEnvDetail, getExternalHost, getEnvForContaUsLink } from '../../../apiconfig';
import { Modal, Button } from 'react-bootstrap';
import '../assets/styles/custom-styles.css';
import '../assets/styles/media-styles.css';
import '../assets/styles/sidebar.css';
import TagManager from 'react-gtm-module';

const urlParams = new URLSearchParams(window.location.search);
let home = getNewDomain();
const cid = urlParams.getAll('cid');


export default function EmailComponent (props){
 
  const language = props.language;
    const [inputFields, setInputFields] = useState({
        toFName: "",
        toLName: "",
        toEmail: "",
        fromFName: "",
        fromLName: "",
        // fromEmail: "",
        
      });
      const [errors, setErrors] = useState({});
      const [submitting, setSubmitting] = useState(false);
      const [frmType, setfrmType] = useState("myself")
      const [showError, setShowError] = useState(false);
    
    const productName = props.productDetail.productName[0];
    const productId = props.productDetail.productId;
    const fileType = props.productDetail.fileType[0];
    const supportType = props.productDetail.supportType[0];
    const subCategory = props.productDetail.support_sub_category[0];
    const privacyPolicyLink = `${getExternalHost()}us/${language.toLowerCase()}/privacy-policies.html`;
    let hostName = getEnvForSearch();
    const sendEmail = async (e, formType) => {
      e.preventDefault();
     if(formType == "myself"){
        setErrors(validateValues(inputFields, "myself"));
      }
      if(formType== "colleague"){
        setErrors(validateValues(inputFields, "colleague"));
      }
      
      setSubmitting(true);
    
    };

    const validateValues = (inputValues, formType) => {
        let errors = {};
        if(formType == "myself"){
          setfrmType("myself")
          if (inputValues.toFName == null || inputValues.toFName == "" ) {
            
            errors.toFName = "Please enter First Name";
          }
          if (inputValues.toLName == null || inputValues.toLName == "" ) {
              errors.toLName = "Please enter Last Name";
            }
        if (inputValues.toEmail.length < 15) {
          errors.toEmail = "Enter the email address in the correct format";
        }
      }
      if(formType == "colleague"){
        setfrmType("colleague")
      if (inputValues.toEmail.length < 15) {
        errors.toEmail = "Enter the email address in the correct format";
      }
        if (inputValues.toFName == null || inputValues.toFName == "" ) {
            
          errors.toFName = "Please enter First Name";
        }
        if (inputValues.toLName == null || inputValues.toLName == "" ) {
            errors.toLName = "Please enter Last Name";
          }
          if (inputValues.fromFName == null || inputValues.fromFName == "" ) {
            errors.fromFName = "Please enter First Name";
          }
          if (inputValues.fromLName == null || inputValues.fromLName == "" ) {
              errors.fromLName = "Please enter Last Name";
            }
          }
        return errors;
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setInputFields({ ...inputFields, 
          [name]: value,
         });
        
      };

      const finishSubmit = async (productName, frmType, fileType, supportType, subCategory) => {
        
        let subject = "Support Asset for Parker product";
        let body = `<p>Below please find the link to the Support Asset from Parker.com  that you requested. If you have product questions, need technical assistance, or require additional information, 
                            Please` + "<a href='https://"+getEnvForContaUsLink() +".parker.com/us/"+ language.toLowerCase() + "/contact.html'> Contact Us</a></p>";
        if(frmType == "colleague"){
          const frmName = inputFields.fromFName;
          const frmlName = inputFields.fromLName;
          subject = "Support Asset for Parker product from " + frmName + " " + frmlName;
          body = `<p> ${frmName} ${frmlName} has sent you the link below to the the Support Asset from MyParker  that you requested. If you have product questions, need technical assistance, or require additional information, 
          Please` + "<a href='https://" + getEnvForContaUsLink() + ".parker.com/us/"+ language.toLowerCase() + "/contact.html'> Contact Us</a></p>";
        }
        let productLink = `<a href="https://myparker${hostName}.parker.com/${language}/myparker/search?sort=default&searchbox=${productName}&id_s=${productId}&cid=${cid}">
                        
        ${productName}</a>`;
        
        if(hostName == "preview"){
          hostName = "stage";
          productLink = `<a href="https://myparker${hostName}.parker.com/${language}/myparker/search?sort=default&searchbox=${productName}&id_s=${productId}&cid=${cid}">
                        
        ${productName}</a>`;
        } else if (hostName == "prod"){
         
          productLink = `<a href="https://myparker.parker.com/${language}/myparker/search?sort=default&searchbox=${productName}&id_s=${productId}&cid=${cid}">
          ${productName}</a>`;
        }
        console.log("hostname", hostName)
        try { 
          let response2 = await fetch( getHostForDA() + "/cms/v1/send-email", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'ocp-apim-subscription-key': getAPIAuthKey(),  
                   },
                   body: 
                   JSON.stringify({
                            "to": [
                              inputFields.toEmail
                            ],
                            // "subject": "Support Asset for Parker product from " + inputFields.fromFName ? inputFields.fromFName : "" + " " + inputFields.fromLName ? inputFields.fromLName : ""
                            "subject": subject

                            ,
                            "body": `${body}

                        <br>Thank You for visiting MyParker<br>` + productLink
                        
                        })
          }
        )
        .then((response) => console.log(response.json()))
        TagManager.dataLayer({
          dataLayer: {
            event: "email_support",
            file_type: fileType,
            support_title: productName,
            support_type: supportType,
            support_sub_category: subCategory

          }
        })
        return response2;
        } catch (error) {
          console.error("Error fetching data:", error);
        }

       
       
      };
      useEffect(() => {
        if (Object.keys(errors).length === 0 && submitting) {
          finishSubmit(productName, frmType, fileType, supportType, subCategory);

          setTimeout(() => {
            document.getElementById("successMessage").style.display = "none";
          }, "2000");
         

            alert("Email Sent Successfully!")
            props.handleClose()
           // document.getElementById("exampleModal").classList.remove("show", "d-block");
            // document.getElementById("exampleModal").style.display = "none";
            
              document.querySelectorAll(".modal-backdrop")
              .forEach(el => el.classList.remove("modal-backdrop")); 
                  
                  setInputFields({
                    toFName: "",
                    toLName: "",
                    toEmail: "",
                    fromFName: "",
                    fromLName: "",
                    // fromEmail: "",
                    
                  })

//  element.setAttribute('data-dismiss', "modal");
        }
       
      }, [errors]);

      const cancelSendEmail = (e) => {
        e.preventDefault();
        props.handleClose();
      }
   
    return (
        <div>
            {/* <div class="modal fade" id="exampleModal" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"> */}
       
            <div className="modal-header" style={{padding: 0}}>
              
              <h4 className="modal-title" id="myModalLabel">EMAIL THIS PAGE</h4>
              <button type="button" className="close" onClick={props.handleClose} aria-label="Close"
              style={{
                minWidth: 0,
                background: "none",
                border: "unset",
                
              }}        
              ><span aria-hidden="true" style={{fontSize: "25px"}}>×</span></button>
            </div>
            <div className="modal-body">
                  <div>
              <p>Please enter your information below.</p>
              {Object.keys(errors).length === 0 && submitting ? (
    <span className="success" id="successMessage">Email sent successfully! ✓</span>
  ) : null}
       <div style={{display: "flex"}}>
                              <span><b>Email To:</b></span>
                              <div style={{marginLeft: "3rem"}}>
                    <ul class="nav nav-pills email-tabs" id="pills-tab" role="tablist" style={{cursor: "pointer"}}>
        <li className="myself active"><span data-target="#myself" data-toggle="pill">MYSELF</span></li>
        <li className="colleague"><span data-target="#colleague" data-toggle="pill">COLLEAGUE</span></li>
      </ul>
      </div>
      </div>
      <br />
      <span style={{color: "red",minWidth: "0px",paddingTop: "0px"}}>*</span><span className=" required-info">Required Information</span>
      <br />
      <div class="tab-content clearfix">
        <div class="tab-pane active" id="myself">
                  <form id="myForm" name="myForm" onSubmit={(e) => sendEmail(e,"myself")}>
						{/* <input type="text"  type="hidden" id="isMySelfSelected" value=""/>
				  	<input type="text"  type="hidden" id="productURL" value="no"/>
					<input type="text"  type="hidden" id="productName" value=""/>
					<input type="text"  type="hidden" id="localeCode" name="localeCode" value="<%=localeCode%>"/>
					<input type="text"  type="hidden" id="cadConfigCheckEmail" value="" />
					<input type="text"  type="hidden" id="searchResultPage" value="product" />
					<input type="text"  type="hidden" id="fileType" value="" />
					<input type="text"  type="hidden" id="supportType" value="" />
					<input type="text"  type="hidden" id="supportSubType" value="" /> */}

               
                        {/* <div>
                              <span>Email to:</span>
                              <div className="radio-btns"> <a className="keywordbtn" href="javascript:void(0)" onclick="javaScript:hideFromAddress();">My Self</a> 
							  <a className="partNumberBtn select" href="javascript:void(0)" onclick="javaScript:showFromAddress();" style={{textDecoration: "underline"}}>Colleague</a> </div>
                              <p><span style={{color:"red",minWidth:"0px",paddingTop:"0px"}}>* </span>required information</p>
                        </div> */}
                       
                        <div id="emailTo">
                              <span className="dis_block"><b>To :</b> </span>
                              <table>
	                              <tbody>
								  <tr>
	                              	<td id="toTd">
                                        
                                  <div className="float_left">
			                              	 <label classname="float_left">First Name:<span id="spanTo" style={{color: "red", minWidth: "25px",paddingTop: "0px"}}>*</span></label>
			                              	<input type="text"  classname="float_left col-xs-12" style={{height: "24px",  resize: "auto"}} name="toFName" id="toFName" 
                                            value={inputFields.toFName}
                                            onChange={handleChange}                                           />
			                              	{/* <label classname="float_left clearBoth emailErrorMsg" id="errorMsgToFName"></label> */}
                                              {errors.toFName ? (
                                                <label className="error">
                                                    Please enter your first name
                                                </label>
                                                ) : null}
		                              	</div>																				
		                              	<div className="toEmailData float_left">
			                              	<label classname="float_left">Last Name:<span style={{color: "red",minWidth: "25px", paddingTop: "0px"}}>*</span></label>
			                              	<input type="text"  classname="col-xs-12" style={{height: "24px",}} name="toLName" id="toLName"
                                            value={inputFields.toLName}
                                            onChange={handleChange}
                                           />
			                              	{errors.toLName ? (
  <label className="error">
    Please enter your last name
  </label>
) : null}
		                              	</div>
                                         
										</td>
	                              </tr>
	                              
	                          </tbody></table>
	                         
	                                             <table>
                        <tbody>
                           <tr>
                              <td id="toEmail">
                                 <div className="float_left">
                                     <label className="float_left">Email Address:<span style={{color: "red",minWidth: "0px",paddingTop: "0px"}}>*</span></label>
									 <input type="text"  style={{height: "24px",width: "250px"}} className="col-xs-12" id="toEmail" name="toEmail"
                                     value={inputFields.toEmail}
                                     onChange={handleChange}
                                    />
                                     {errors.toEmail ? (
                                        <label className="error">
                                            Please enter your correct email
                                        </label>
                                        ) : null}
                                 </div>                                
                              </td>
                           </tr>
                        </tbody>
                     </table>
		                </div>
		                
		                
                        {/* <div id="emailFrom">
                              <span className="dis_block">From :</span>
                              <table>
	                              <tbody>
								   <tr>
	                              	<td id="fromTd">
	                              		<div className="float_left">
			                              	<label className="float_left">First Name :<span style={{color: "red",minWidth:"25px",paddingTop: "0px"}}>*</span></label>
			                              	<input type="text"  className="col-xs-12" style={{height: "24px", }} name="fromFName" id="fromFName"
                                            value={inputFields.fromFName}
                                            onChange={handleChange}
                                           />
			                              	{errors.fromFName ? (
  <label className="error">
    Please enter first name
  </label>
) : null}
		                              	</div>
										<div className="float_left fromEmailData">
		                              		<label className="float_left">Last Name:<span style={{color:"red",minWidth: "25px",paddingTop:"0px"}}>*</span></label>
		                              		<input type="text"  className="col-xs-12" style={{height: "24px", }} name="fromLName" id="fromLName"
                                            value={inputFields.fromLName}
                                            onChange={handleChange}
                                           />
		                              		{errors.fromLName ? (
  <label className="error">
    Please enter last name
  </label>
) : null}
		                              	</div>
										</td>
	                              </tr>
	                              
	                          </tbody></table>
	                         <table>
							<tbody>
							   <tr>
								  <td id="toEmail">
									 <div>
										 <label className="float_left">Email Address:<span style={{color: "red", minWidth: "0px", paddingTop: "0px"}}>*</span></label>
										 <input type="text"   style={{height: "24px",width: "250px"}} className="col-xs-12" id="fromEmail" name="fromEmail"
                                         value={inputFields.fromEmail}
                                         onChange={handleChange}
                                        />
										 {errors.fromEmail ? (
  <label className="error">
    Please enter correct email.
  </label>
) : null}
									 </div>                                
								  </td>
							   </tr>
							</tbody>
						 </table>
                        </div> */}
                        
                     
                 <br />
				  <div className="">
				
					<div  style={{color: "white"}}>   
						<table className="table" style={{height: "0px"}}>
							<tr>
								<th className="" style={{backgroundColor: "#424242",fontSize: "12px"}}>				
									<span className="pull-left product-info" style={{color: "white", background: "none"}}>Product Information</span>				
								</th>							
							</tr>
						</table>
					</div>
					
					<div className="">
						<div className="">
              
              <h3 className="productNameH3">{productName}</h3>
              <hr />
              </div>
					</div>

						
					<table className="table prod-table" style={{height: "0px"}}>           
						<tbody>
							<tr>
								<td>
									<div className="product-img" style={{width: "100%"}}>
										
                  <img src={props.productDetail.productImg} />
										
									</div>				
									<div className="product" style={{padding: "10px"}}> 				  
										<div className="product-info">
                    <p>{props.productDetail.productDesc}</p>
										</div>                
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<div style={{textAlign: "right"}}>
					<button type="submit" id="send-email-btn-1" className="btn btn-default btn-primary" style={{color: "white",
          width: "50%",
          paddingTop: "3px",
             paddingBottom: "3px",
         
          backgroundColor: "rgb(66, 66, 66)",
          borderRadius: "unset",
          border: "1px solid #000",
          fontSize: "12px"
        }}>
					Send Email</button>
					  <button className="btn btn-default btn-primary cancel-email-btn" 
            onClick={(e) => cancelSendEmail(e)}
            style={{color: "#000",
            width: "50%",
            paddingTop: 0,
             paddingBottom: 0,
            
            backgroundColor: "white",
            borderRadius: "unset",
            border: "1px solid #000",
            fontSize: "12px"
          
          }}
          data-dismiss="modal"
            >Cancel</button>					
					 </div>
				</div>
                </form> 
                </div>
                <div class="tab-pane" id="colleague">
                  <form id="myForm1" name="myForm1" onSubmit={(e) => sendEmail(e, "colleague")}>
						{/* <input type="text"  type="hidden" id="isMySelfSelected" value=""/>
				  	<input type="text"  type="hidden" id="productURL" value="no"/>
					<input type="text"  type="hidden" id="productName" value=""/>
					<input type="text"  type="hidden" id="localeCode" name="localeCode" value="<%=localeCode%>"/>
					<input type="text"  type="hidden" id="cadConfigCheckEmail" value="" />
					<input type="text"  type="hidden" id="searchResultPage" value="product" />
					<input type="text"  type="hidden" id="fileType" value="" />
					<input type="text"  type="hidden" id="supportType" value="" />
					<input type="text"  type="hidden" id="supportSubType" value="" /> */}

               
                        {/* <div>
                              <span>Email to:</span>
                              <div className="radio-btns"> <a className="keywordbtn" href="javascript:void(0)" onclick="javaScript:hideFromAddress();">My Self</a> 
							  <a className="partNumberBtn select" href="javascript:void(0)" onclick="javaScript:showFromAddress();" style={{textDecoration: "underline"}}>Colleague</a> </div>
                              <p><span style={{color:"red",minWidth:"0px",paddingTop:"0px"}}>* </span>required information</p>
                        </div> */}
                       
		                
                       <div id="emailTo">
                              <span className="dis_block"><b>To :</b> </span>
                              <table>
	                              <tbody>
								  <tr>
	                              	<td id="toTd">
                                        
	                              		<div className="float_left">
			                              	 <label classname="float_left">First Name:<span id="spanTo" style={{color: "red", minWidth: "25px",paddingTop: "0px"}}>*</span></label>
			                              	<input type="text"  classname="float_left col-xs-12" style={{height: "24px",  resize: "auto"}} name="toFName" id="toFName" 
                                            value={inputFields.toFName}
                                            onChange={handleChange}                                           />
			                              	{/* <label classname="float_left clearBoth emailErrorMsg" id="errorMsgToFName"></label> */}
                                              {errors.toFName ? (
                                                <label className="error">
                                                    Please enter your name
                                                </label>
                                                ) : null}
		                              	</div>																				
		                              	<div className="toEmailData float_left">
			                              	<label classname="float_left">Last Name:<span style={{color: "red",minWidth: "25px", paddingTop: "0px"}}>*</span></label>
			                              	<input type="text"  classname="float_left col-xs-12" style={{height: "24px",}} name="toLName" id="toLName"
                                            value={inputFields.toLName}
                                            onChange={handleChange}
                                           />
			                              	{errors.toLName ? (
  <label className="error">
    Please enter your Last name
  </label>
) : null}
		                              	</div>
                                         
										</td>
	                              </tr>
	                              
	                          </tbody></table>
	                         
	                                             <table>
                        <tbody>
                           <tr>
                              <td id="toEmail">
                                 <div className="float_left">
                                     <label className="float_left">Email Address:<span style={{color: "red",minWidth: "0px",paddingTop: "0px"}}>*</span></label>
									 <input type="text"  style={{height: "24px",width: "250px"}} className="col-xs-12" id="toEmail" name="toEmail"
                                     value={inputFields.toEmail}
                                     onChange={handleChange}
                                    />
                                     {errors.toEmail ? (
                                        <label className="error">
                                            Please enter your correct email
                                        </label>
                                        ) : null}
                                 </div>                                
                              </td>
                           </tr>
                        </tbody>
                     </table>
		                </div>
                       <div id="emailFrom">
                              <span className="dis_block"><b>From :</b></span>
                              <table>
	                              <tbody>
								   <tr>
	                              	<td id="fromTd">
	                              		<div className="float_left">
			                              	<label className="float_left">First Name :<span style={{color: "red",minWidth:"25px",paddingTop: "0px"}}>*</span></label>
			                              	<input type="text"  className="col-xs-12" style={{height: "24px", }} name="fromFName" id="fromFName"
                                            value={inputFields.fromFName}
                                            onChange={handleChange}
                                           />
			                              	{errors.fromFName ? (
  <label className="error">
    Please enter first name
  </label>
) : null}
		                              	</div>
										<div className="float_left fromEmailData">
		                              		<label className="float_left">Last Name:<span style={{color:"red",minWidth: "25px",paddingTop:"0px"}}>*</span></label>
		                              		<input type="text"  className="col-xs-12" style={{height: "24px", }} name="fromLName" id="fromLName"
                                            value={inputFields.fromLName}
                                            onChange={handleChange}
                                           />
		                              		{errors.fromLName ? (
  <label className="error">
    Please enter last name
  </label>
) : null}
		                              	</div>
										</td>
	                              </tr>
	                              
	                          </tbody></table>
	                         {/* <table>
							<tbody>
							   <tr>
								  <td id="toEmail">
									 <div>
										 <label className="float_left">Email Address:<span style={{color: "red", minWidth: "0px", paddingTop: "0px"}}>*</span></label>
										 <input type="text"   style={{height: "24px",width: "250px"}} className="col-xs-12" id="fromEmail" name="fromEmail"
                                         value={inputFields.fromEmail}
                                         onChange={handleChange}
                                        />
										 {errors.fromEmail ? (
  <label className="error">
    Please enter correct email.
  </label>
) : null}
									 </div>                                
								  </td>
							   </tr>
							</tbody>
						 </table> */}
                        </div>
                        
                     <br />
                 
				  <div className="" style={{width: "100%"}}>
				
					<div  style={{color: "white"}}>   
						<table className="table" style={{height: "0px"}}>
							<tr>
								<th className="" style={{backgroundColor: "#424242",fontSize: "12px"}}>				
									<span className="pull-left product-info" style={{color: "white", background: "none"}}>Product Information</span>				
								</th>							
							</tr>
						</table>
					</div>
					
					<div className="">
						<div className=""><h3 className="productNameH3">{productName}</h3>
            <hr />
            
            </div>
					</div>
						
					<table className="table prod-table" style={{height: "0px"}}>           
						<tbody>
							<tr>
								<td>
									<div className="product-img" style={{width: "100%"}}>
										
                  <img src={props.productDetail.productImg} />
										
									</div>				
									<div className="product" style={{padding: "10px"}}> 				  
										<div className="product-info">
											<p>{props.productDetail.productDesc}</p>
										</div>                
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<div style={{textAlign: "right"}}>
					<button type="submit" id="send-email-btn-2" className="btn btn-default btn-primary " 
          style={{color: "#000",
          width: "50%",
          paddingTop: "3px",
             paddingBottom: "3px",
          color: "white",
          backgroundColor: "rgb(66, 66, 66)",
          borderRadius: "unset",
          border: "1px solid #000",
          fontSize: "12px"
        }}
        
          >
					Send Email</button>
					  <button className="btn btn-default btn-primary cancel-email-btn"
             style={{color: "#000",
             width: "50%",
             paddingTop: 0,
             paddingBottom: 0,
             
             backgroundColor: "white",
             borderRadius: "unset",
             border: "1px solid #000",
             fontSize: "12px"
           }}
           onClick={(e) => cancelSendEmail(e)}
           data-dismiss="modal"
            >Cancel</button>					
					 </div>
				</div>
                </form> 
                </div>
                </div>
				 </div>
         
		 </div>

			<div className="container" style={{textAlign: "center"}}>
			
			<p style={{textAlign: "center"}}>View Our <a href={privacyPolicyLink}  target="_blank">Privacy Policy</a></p>
			
		</div>
         
      </div>
        // </div>

    )

}