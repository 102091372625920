import React from 'react';
import './mpHelp.css';
import Breadcrumb from '../support/Breadcrumb';
import * as translationUtils from '../../common-utils/translation_utils';

const MyParkerHelp = (props) => {
  return (
    <>
      <Breadcrumb
        pageName={translationUtils.getTranslation(2140, 'MyParker Help')}
        cid={props.cid}
        lang={props.lang}
      />
      <div className='parker-training-content'>
        <div className='row max-900 mx-auto justify-content-md-center px-md-4 px-lg-3'>
          <div
            className='col-12 text-center'
            style={{ backgroundColor: '#ffffff' }}>
            {/* <h1>MyParker Help</h1> */}
            <ul className='d-flex justify-content-center ph-tops pb-5 pt-5'>
              <li className='p-2 flex-fill'>
                <a
                  href='https://discover.parker.com/MyParkerTraining'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <img
                    src='../../assets/img/region-northAmerica.JPG'
                    alt={translationUtils.getTranslation(80, 'North America')}
                  />
                  {translationUtils.getTranslation(80, 'North America')}
                </a>
              </li>
              <li className='p-2 flex-fill'>
                <a
                  href='https://discover.parker.com/MyParker-Training-LA'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <img
                    src='../../assets/img/region-latinAmerica.JPG'
                    alt={translationUtils.getTranslation(81, 'Latin America')}
                  />
                  {translationUtils.getTranslation(81, 'Latin America')}
                </a>
              </li>
              <li className='p-2 flex-fill'>
                <a
                  href='https://discover.parker.com/MyParkerTraining_EMEA'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <img
                    src='../../assets/img/region-europe.JPG'
                    alt={translationUtils.getTranslation(2141, 'EMEA')}
                  />
                  {translationUtils.getTranslation(2141, 'EMEA')}
                </a>
              </li>
              <li className='p-2 flex-fill'>
                <a
                  href='https://discover.parker.com/MyParker-Training-APAC'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <img
                    src='../../assets/img/region-asia.JPG'
                    alt={translationUtils.getTranslation(78, 'Asia Pacific')}
                  />
                  {translationUtils.getTranslation(78, 'Asia Pacific')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyParkerHelp;
