import React, { Component } from 'react'
import * as translationUtils from '../../common-utils/translation_utils';
class TemporaryPassword extends Component {
    onCopyPassword(){
        var copyText = document.getElementById("csvfile");
        copyText.select();
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
    }
    onCloseModel(){
       // console.log("OnCloModel");
        this.props.onRefreshModel();
    }
    render() {
        return (
            <div>
                
                <div className="ph-modal fade tempPassword" id="temporarypasswordModal" tabindex="-1" role="dialog" aria-labelledby="Temporary Password Modal"
        aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="ph-modal__content">
                            <div className="d-flex justify-content-end m-0 p-0">
                                <span data-dismiss="modal" aria-label="Close" className="ph-modal__header--close ph-modal-header-close-font-size" aria-hidden="true"><i
                                        className="fas fa-times" onClick={()=>this.onCloseModel()} ></i></span>
                            </div>
                            <div className="ph-modal__header">
                                <h3 className="ph-modal__title" id="temporarypasswordModalLabel">{translationUtils.getTranslation(58, "Reset")+' '+translationUtils.getTranslation(1274, "Password")}</h3>
                            </div>
                            {this.props.tmpPas ?
                            <div className="ph-modal__body">
                                <div>
                                    {(this.props.message!="" && this.props.message!=undefined) && <i className="fa fa-check-circle ph-modal-success-message-color" aria-hidden="true"></i>}
                                    {/* <span className="ph-modal-success-message-color bold pl-3">The user's password updated with a temporary password</span> */}
                                    <span className="ph-modal-success-message-color bold pl-3">{this.props.message}</span>
                                </div>                    
                                <div className="row ph-form-import-row pt-4">
                                    <div className="col-12 col-sm-8">
                                        <label className="ph-form-row__label" for="quantity">{translationUtils.getTranslation(2240, "The Temporary Account Password Is:")} </label>
                                        <input className="ph-form-row__input" type="text" id="csvfile" value={this.props.tmpPas}/>
                                    </div>
                                    <div className="col-6 col-sm-4 padding-browse">
                                        <button type="button" className="button round-button small-button" onClick={()=>this.onCopyPassword()}>{translationUtils.getTranslation(2241, "Copy")}</button>
                                    </div>                  
                                </div>
                                <div className="row ph-form-import-row">
                                    <div className="col-12">
                                    {translationUtils.getTranslation(2216,"The user will be required to set a new password as soon as")}  <br/>{translationUtils.getTranslation(2217," they log in.")}
                                    </div>                 
                                </div>
                            </div>:(this.props.message!="" && this.props.message!=undefined)?
                            (<div className="ph-modal__body">
                            <div>
                                <i className="fa fa-times-circle-o password-1-day-color" aria-hidden="true"></i>
                                {/* <span className="ph-modal-success-message-color bold pl-3">The user's password updated with a temporary password</span> */}
                                <span className="password-1-day-color bold pl-3">{this.props.message}</span>
                            </div> 
                            </div>):""
                            }
                            <div className="ph-modal__footer">
                                <button type="button" className="button accent-button round-button small-button"
                                    data-dismiss="modal" onClick={()=>this.onCloseModel()}>Close</button>
                            </div>
                        </div>
                    </div>
                    </div>
                  
            </div>
        )
    }
}
export default TemporaryPassword;
