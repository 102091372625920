import React, {useState, useEffect} from "react";
import CrossReference from "./CrossReference";
import { FilterSidebar } from "./FilterSidebar";
import { NoResultPage } from "./NoResultPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import { getHostForDA, getAPIAuthKey, getPhEnvDetail, getEnvForSearch, getExternalHost } from '../../../apiconfig.js';
import {  getPid } from '../utils/FacetKeys';
import * as translationUtils from '../../../common-utils/translation_utils';
import EmailComponent from "./EmailComponent";
import TagManager from 'react-gtm-module';
import { Modal, Button } from 'react-bootstrap';

export default function Results(props){
   var isProduct = props.tabResult;
   let myposts = props.resultInfo;
   const urlParams = new URLSearchParams(window.location.search);
   const searchbox = urlParams.getAll('searchbox');
   const searchWithIn = urlParams.getAll('search_within');
   const [sidepanelfour, setSidePanelFour] = useState(false)
   const [clearInputSearchValue, setClearInputSearchValue] = useState(false)

 const language = props.language;

 const supportPid = sessionStorage.getItem("supportpid");
 const productPid = sessionStorage.getItem("productpid");
 const xrefPid = sessionStorage.getItem("xrefpid");
 const [showModal, setShowModal] = useState(true);

 const [selectedId, setSelectedId] = useState({productName: "", productImg:"", productDesc: "", productId: "", fileType: "", supportType: "", support_sub_category: "" });

//   const handleClose = () => setShowModal(false);
//   const handleShow = () => setShowModal(true);

const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getProductDetailForEmail = (productName, productImg, productDesc, productId, fileType, support_type, support_sub_category) => {
   setShow(true);
   //  setShowModal(true);
   //  document.getElementById("exampleModal").classList.add("show", "d-block");
   // document.getElementById("exampleModal").style.display = "block";
   setSelectedId((prevData) => ({
      ...prevData,
      productName: productName,
      productImg: productImg,
      productDesc: productDesc,
      productId: productId,
      fileType: fileType,
      supportType: support_type,
      support_sub_category: support_sub_category,
    }));
  }

//   function getCookie(name) {
//    var cookies = document.cookie.split('; ');

//    for (var i = 0; i < cookies.length; i++) {
//      var cookie = cookies[i].split('=');

//      if (cookie[0] === name) {
//        return cookie[1];
//      }
//    }
//    return 'N/A';
//  }

// useEffect(()=>{
  
//    var previousURL = getCookie('previousURL');
//    if (decodeURIComponent(previousURL) == window.location.href) {
//       previousURL = getCookie('previousURL1');
//     }
//     document.cookie =
//       'previousURL=' +
//       encodeURIComponent(window.location.href) +
//       '; domain=parker.com; path=/';
//    TagManager.dataLayer({
//       dataLayer: {
//         event: 'page_view',
//         page_name: 'Myparker Search',
//         page_referrer: decodeURIComponent(previousURL),
//         content_group: 'myparker: help and support',
//         country: 'USA',
//         language: sessionStorage.getItem('defaultLang')
//           ? sessionStorage.getItem('defaultLang')
//           : 'EN',
//         content_owner: '687PDC',
//         nav_owner: '687PDC',
//         product_category_name: 'N/A',
//         product_series_name: 'N/A',
//         product_series_id: 'N/A',
//         product_id: 'N/A',
//         part_name: 'N/A',
//         part_number: 'N/A',
//         is_category_page: 'No',
//         is_series_page: 'No',
//         is_pdp: 'No',
//         is_req_quote: 'No',
//         is_wtb: 'No',
//         is_buyable_online: 'No',
//         is_configurable: 'No',
//         user_ip: window.sessionStorage.getItem('ClientIPAddress'),
//         user_id: window.sessionStorage.getItem('WCTrustedToken')
//           ? window.sessionStorage.getItem('WCTrustedToken').split('%')[0]
//           : 'N/A',
//         user_authenticated: 'authenticated user - MyParker Search',

//         user_company_name: window.sessionStorage.getItem('orgListWithName')
//           ? window.sessionStorage.getItem('orgListWithName')
//           : 'N/A',
//         user_company_id: window.sessionStorage.getItem('userCompanyId')
//           ? window.sessionStorage
//               .getItem('userCompanyId')
              
//           : 'N/A',

//         // user_company_id: window.sessionStorage.getItem('orgListWithId')
//         //   ? window.sessionStorage.getItem('orgListWithId').replace(/[()]/g, '')
//         //   : '',
//       },
//     });
// }, [])
  
function convertDate(dateString){
const date = new Date(dateString);

// Get the day, month, and year
const day = date.getUTCDate();
const month = date.toLocaleString('default', { month: 'short' }); 
const year = date.getUTCFullYear();

// Format the date string
const formattedDate = `${day} ${month} ${year}`;

return formattedDate;

  }

 function getProductLink(item){
   let partType = item.siteSubSection_ss;
   if(partType != ""){
      partType = Array.isArray(partType) ?  partType[0] : partType;
   }
   let supportType = item.supportType_ss;
   if(supportType != ""){
      supportType = Array.isArray(supportType)  ?  supportType[0] : supportType;
   }
   let siteSection =  item.siteSection_ss;

   if(siteSection != ""){
      siteSection = Array.isArray(siteSection) ?  siteSection[0] : siteSection;
   }
  
   let fileType =  item.fileType_ss;

   if(fileType != ""){
      fileType = Array.isArray(fileType) ?  fileType[0] : fileType;
   }

            let contentSource = item.contentSource_s;
            let url;
               if(partType === 'Part' ||  supportType=== 'CAD'){

               url =  undefined || ' ' !== item.urlKeyword_s 
                          ? `https://`+getPhEnvDetail()+`.parker.com/`+`us`+`/${language.toLowerCase()}/${item.psURLKeyword_s}/${item.urlKeyword_s}`
                          : `https://`+getPhEnvDetail()+`.parker.com/`+`us`+`/${language.toLowerCase()}/${item.urlKeyword_s}`;
              } else if(partType === 'Product Series'){
               url = `https://`+getPhEnvDetail()+`.parker.com/`+`us`+`/${language.toLowerCase()}/product-list/${item.urlKeyword_s}`;

              } else if(contentSource === 'Tacton'){
               url = `https://econfig.parker.com.parker.com/?session=${item.urlKeyword_s}&lang=${language.toLowerCase()}&countryID=687PDC}`;
              } else if( contentSource ===  'AEM-Support'){
               
                 if(fileType === "PDF" ||  fileType === "MP4" || fileType === "MOV" || fileType === "ZIP" || fileType === "XLSX" || fileType === "PPTX" || fileType === "XLS" 
                  || fileType === "XLSM" || fileType === "XLSB" || fileType === "PPT" || fileType === "DOCX" || fileType === "CSV"){


                     url = `https://${getExternalHost()}${item.urlKeyword_s}`;

                 } else {

                  url = item.urlKeyword_s;
                 }
                 
              } else if( contentSource ===  'Analytics'){
               url = `https://`+getPhEnvDetail()+`.parker.com/`+`us`+`/${language.toLowerCase()}/${item.urlKeyword_s}`;
              } else if(  contentSource ===  'WebSphere'){
                  if(partType === 'Part' ||  supportType === 'CAD'){

                  url =  undefined || ' ' !== item.urlKeyword_s 
                         ? `https://`+getPhEnvDetail()+`.parker.com/`+`us`+`/${language.toLowerCase()}/${item.psURLKeyword_s}/${item.urlKeyword_s}`
                         : `https://`+getPhEnvDetail()+`.parker.com/`+`us`+`/${language.toLowerCase()}/${item.urlKeyword_s}`;
                  }else if(partType === 'Product Category'){
                     url = `https://`+getPhEnvDetail()+`.parker.com/`+`us`+`/${language.toLowerCase()}/category/${item.urlKeyword_s}`;
                  }else if(partType === 'Product Series'){
                     url = `https://`+getPhEnvDetail()+`.parker.com/`+`us`+`/${language.toLowerCase()}/series/${item.urlKeyword_s}`;
                  }else if(supportType === "Configurator"){
                     url = item.configURL_s;
                   }else{
                        url = `https://`+getPhEnvDetail()+`.parker.com/`+`us`+`/${language.toLowerCase()}/${item.urlKeyword_s}`;
                     }
            }else if(contentSource == "WebCrawl-IS" && siteSection === "Support" ){
               url =item.urlKeyword_s;
              }
            
            else{
               url = `https://`+getPhEnvDetail()+`.parker.com/`+`us`+`/${language.toLowerCase()}/${item.urlKeyword_s}`;

              }
              return url;
 }

 function removeBlobFromURL(blobURL) {
   // Use a regular expression to extract the part after "blob:"
   const match = blobURL.match(/^blob:(.*)$/);
 
   if (match && match[1]) {
     return match[1];
   } else {
     return blobURL; // Return the original URL if it doesn't match
   }
 }

 const downloadPrivateAssets = async (docName, fileExt, assetId, supportResourceType
   , supportType, otDivDescription) => {

   TagManager.dataLayer({
      dataLayer: {
      event: "select_download_type",
      support_title: docName,
      support_type: supportType[0],
      support_sub_category: supportResourceType[0],
      file_type: fileExt[0],
      content_owner: otDivDescription,
      user_ip: window.sessionStorage.getItem('ClientIPAddress'),
      user_id: window.sessionStorage.getItem('WCTrustedToken')
        ? window.sessionStorage.getItem('WCTrustedToken').split('%')[0]
        : 'N/A',
      user_authenticated: 'authenticated user - MyParker',

      user_company_name: window.sessionStorage.getItem('orgListWithName')
          ? window.sessionStorage.getItem('orgListWithName')
          : 'N/A',
        user_company_id: window.sessionStorage.getItem('userCompanyId')
          ? window.sessionStorage
              .getItem('userCompanyId')
              
          : 'N/A',
      }
   })
 
   let distId = sessionStorage.getItem("distributorId");
   let berarerToken = sessionStorage.getItem("oktaSessionId");

   return axios({
     method: "GET",
     responseType: "blob",
     url: getHostForDA() + "/support/myparker-asset-authorizer/parker/OpenContent?id="+assetId+"&distributorId="+distId,
     headers: {
      
       "ocp-apim-subscription-key": getAPIAuthKey(),
       "Content-Disposition": "attachment; filename=file name.jpg",
       'Authorization': 'Bearer ' + berarerToken,
     },
     data: { DocumentName: docName, FileNameWithExt: fileExt },
   })
     .then((response) => {
       
       if (response.data.size > 0) {
       
         if (fileExt == "PDF") {
           const blob = new Blob([response?.data], {
             type: "application/pdf",
           });
            let file = new File([blob], docName, { type: "application/pdf", });
       
           const fileURL = URL.createObjectURL(file) + `#${docName}`;
          
           window.open(fileURL, `_blank`);
        
         } else {
            
           const file = new Blob([response?.data]);
           
           const fileURL = URL.createObjectURL(file);
          
           const link = document.createElement("a");
          
           link.href = fileURL;
           
           link.download = docName; // specify the filename
           document.body.appendChild(link);
           link.click();
           document.body.removeChild(link);
         }
       } else {
         alert("Cannot find the document");
       }
     })
     .catch((err) => {
      
     });
 };


  function truncateDesription(str, maxLength) {
      if (str?.length <= maxLength) {
         return str;
      }
      return str?.substring(0, maxLength - 3) + "...";

   }

   const clearSelectedcheckedItem = (item, preValve) => {
      const index = props.prevCheckedItem.indexOf(item)
      let dynamicPID = item.split(":")[1]
      if(dynamicPID == urlParams.get(`supportType${language}_fq`)){
         urlParams.delete(`supportType${language}_fq`)
         var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${urlParams.toString()}`;
         window.history.pushState({ path: refresh }, '', refresh);
     }
      console.log("fq_tagggcheck",index )
      if(index !== -1){
          console.log('filtersidebarrrprops.prevCheckedItem', props.prevCheckedItem, item)
          let newCheckedItem = [...props.prevCheckedItem]
          newCheckedItem.splice(index, 1)
          console.log('filtersidebarrrnewCheckedItem', newCheckedItem)
        setCheckedfromChild([...newCheckedItem], 1)
      }
   }

   const setCheckedfromChild = (newstate, pageNum) => {
      console.log('setCheckedfromChild come under result js calll')
      props.setPrevCheckedItem([...newstate], pageNum)
     }
   const setClearAll = () => {
      urlParams.set("search_within", "");
      var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${urlParams.toString()}`;
      window.history.pushState({ path: refresh }, '', refresh);
      let pid = ""
      let fq_tag2 = ''
         if(isProduct == "support"){
            pid = getPid(supportPid);
             fq_tag2 = `{!tag=tag_siteSection${language}_fq}siteSection${language}_fq:`+getPid(pid);
           
        }else if(isProduct == "products"){
            pid = getPid(productPid);

            fq_tag2 = `{!tag=tag_siteSection${language}_fq}siteSection${language}_fq:`+getPid(pid);
      }else if(isProduct == "products"){
         pid = sessionStorage.getItem("productpid");
         fq_tag2 = `{!tag=tag_siteSection${language}_fq}siteSection${language}_fq:`+getPid(pid);
         
      }else{
         pid = sessionStorage.getItem("xrefpid");
         fq_tag2 = `{!tag=tag_siteSection${language}_fq}siteSection${language}_fq:`+getPid(pid);
         
      }

      setClearInputSearchValue(!clearInputSearchValue)
      props.clearFilter();
      console.log('props.prevCheckedItem inside clearall', props.prevCheckedItem)
      let newcheckedItem =[fq_tag2]
      console.log('props.prevCheckedItem inside clearall after', newcheckedItem)

      setCheckedfromChild([...newcheckedItem], 1)
   }


   function openNav(e) {

      if(e.currentTarget.id == "mypanelSupport"){

      

         document.getElementById('body-overlay1').style.display = "block";
      }
      if(e.currentTarget.id == "mypanelProducts"){
      
         document.getElementById('body-overlay').style.display = "block";
      }
      if(e.currentTarget.id == "mypanelXref"){
         document.getElementById('body-overlay2').style.display = "block";
      }
      setSidePanelFour(true);
   }



   const handleCheckboxKey = (key) => {
      let KeyData = Array.isArray(key)
      let label = ''
      if(KeyData) {
         let hasSep1 = key[0].includes('/$$$'),
            hasSep2 = key[0].includes('$$$');



         if (hasSep1) {
            label = key[0].split('/$$$')[1];
            // if(label.includes('0/')){
            //     label = label.split('0/')[1];
            // }
         } else if (hasSep2) {
            label = key[0].split('$$$')[0];
         }
      }
   


      const find = props?.prevCheckedItem?.find((val)=> val.includes(label))    
      //console.info("label check", label,props.prevCheckedItem, find)
      return find;

   }


   const listInputValue = (key) => {
      let KeyData = Array.isArray(key)
      let label = ''
      if(KeyData) {
         let hasSep1 = key[0].includes('/$$$'),
            hasSep2 = key[0].includes('$$$');



         if (hasSep1) {
            label = key[0].split('/$$$')[0];
            if(label.includes('0/')){
                  label = label.split('0/')[1];
            }
         } else if (hasSep2) {
            label = key[0].split('$$$')[1];
         }
      }else {
         let hasSep1 = key.includes('/$$$'),
         hasSep22 = key.includes('$$$');



         if (hasSep1) {
            label = key.split('/$$$')[1];
            if(label.includes(',')){
                  label = label.split(',')[0];
            }
         } else if (hasSep22) {
            label = key.split('$$$')[0];
         }
      }
      
      return label;

   }




   const getSingleItemDetail = (key) => {
      const name = listInputValue(key)
      const id = handleCheckboxKey(key)
      console.info("getSingleItemDetail", key,"---------", name,"++++++++++++++++++++++++++++++++++++", id)



      return {
         name,
         id
      }

   }

 

   function closeNav(e) {



      if(document.getElementById('body-overlay1')){
         document.getElementById('body-overlay1').style.display = "none";
      }
      if(document.getElementById('body-overlay')){
         document.getElementById('body-overlay').style.display = "none";
      }
      if(document.getElementById('body-overlay2')){
         document.getElementById('body-overlay2').style.display = "none";
      }
   
      setSidePanelFour(false);

   }



   function getProductLabel(entry) {
      if(entry.siteSubSection_ss == "Part"){
         return `${translationUtils.getTranslation(1213, "View Product")}`;
      } else if(entry.siteSubSection_ss == "Product Series"){
         if (entry.hasConfig_s === 'Y') return `${translationUtils.getTranslation(1037,'Configure')}`;
         else if (entry.partCount_i === 0 && entry.hasConfig_s === 'N' ) return `${entry.partCount_i} ${translationUtils.getTranslation(218,"Products")}`;
         else {
            if(entry.partCount_i == 1){
               return entry.partCount_i + `${translationUtils.getTranslation(25, "Product")}`;
            } else{
               return entry.partCount_i + `${translationUtils.getTranslation(218, "Products")}`;
            }

         }

      } else if( entry.siteSubSection_ss == "Product Category"){
         return `${translationUtils.getTranslation(1738, "View Category")}`;

      }
   }
    return(
      <div>
      {isProduct == 'products' && 
      <div>
         <div id="body-overlay"></div>
         
             <div class="row products-lists">
             <div class=" col-xl-3 ph-search-sidebar hide-mobile">

             <FilterSidebar filteTab="products" onfetchData = {props.onfetchData} 
             resultAPIData = {myposts} 
             allFacetsData = {props.allFacetsData} 
             resultInfo={props.resultInfo} 
             closeFilterSideBar={closeNav}
             prevCheckedItem = {props.prevCheckedItem}
             setCheckedfromChild = {setCheckedfromChild}
             language = {props.language}
             setClearAll = {setClearAll}
             clearInputSearchValue = {clearInputSearchValue}
             catalogStateUpdate = {props.updtaeCatalogState}
             setSearchBox = {props.setSearchBox}
             />
             </div>
             {props?.resultInfo?.length > 0 ? (
               
            <div className="col-md-12 col-lg-12 col-xl-9 results">
            {sidepanelfour && 
            <div id="mySidepanel" className="sidepanel">
     
     <div className=" top-filter-bar">
     <div className="filters_search">Filters & Search</div>
    

<div><a href="javascript:void(0)" className="closebtn " onClick={closeNav}>×</a></div>

<span className="break-line"><hr /></span>
</div>

<FilterSidebar filteTab="products" onfetchData = {props.onfetchData} 
resultAPIData = {myposts} 
allFacetsData = {props.allFacetsData} 
resultInfo={props.resultInfo} 
closeFilterSideBar={closeNav}
prevCheckedItem = {props.prevCheckedItem}
setCheckedfromChild = {setCheckedfromChild}
language = {props.language}
setClearAll = {setClearAll}
clearInputSearchValue = {clearInputSearchValue}
catalogStateUpdate = {props.updtaeCatalogState}
setSearchBox = {props.setSearchBox}
/>

</div>
}

<div className="mob-all-filter">
      <hr />
         <button class="openbtn btn" id="mypanelProducts" onClick={(e)=>openNav(e)}><span className="all-filter-img"><img alt="no image" src={process.env.PUBLIC_URL +
            '/assets/img/Shape Copy@3x.png'
         } /></span><span>All Filters</span></button>
      <hr />
   </div>
   {props?.resultInfo?.length > 0 &&  (
   <div>
      <div className="filter-list hide-mobile  show-1024">

         <span>{translationUtils.getTranslation(1153, "Filters")}:</span>  

         {props.allFacetsData ? Object.keys(props.allFacetsData).map((keyValue) => {
               return props?.allFacetsData[keyValue]?.map((data) => {
                  const SingleItemDetail = getSingleItemDetail(data)
                  if(props.prevCheckedItem?.indexOf(SingleItemDetail?.id) !== -1){
                     {console.log("getpid",getPid(supportPid))}
                     return SingleItemDetail?.id?.includes(getPid(supportPid)) 
                     || SingleItemDetail?.id?.includes(getPid(productPid))
                     || SingleItemDetail?.id?.includes(getPid(xrefPid))
                     || SingleItemDetail?.name === " " ? <></> : <button className="button round-button outline-button my-1 py-1">
                     {SingleItemDetail?.name}<i className="fa fa-close" onClick={() => clearSelectedcheckedItem(SingleItemDetail.id, props.prevCheckedItem)}></i></button>
                  } else {
                     return <></>
                  }
               }
               )
            }) : <></>}

             <button className="button round-button outline-button my-0 py-1"  onClick={ ()=> setClearAll()}>{translationUtils.getTranslation(912, "Clear All")} <i className="fa fa-close" ></i></button>

      </div>
   
      <div className="hide-largescreen">
         <hr />
            <div className="filter-list">

               <span>{translationUtils.getTranslation(1153, "Filters")}:</span> 

               {props.allFacetsData ? Object.keys(props.allFacetsData).map((keyValue) => {
               return props?.allFacetsData[keyValue]?.map((data) => {
                  const SingleItemDetail = getSingleItemDetail(data)
                  {console.log("getpid",getPid(supportPid))}
                  if(props.prevCheckedItem?.indexOf(SingleItemDetail?.id) !== -1){
                     return SingleItemDetail?.id?.includes(getPid(supportPid)) 
                     || SingleItemDetail?.id?.includes(getPid(productPid))
                     || SingleItemDetail?.id?.includes(getPid(xrefPid))
                     || SingleItemDetail?.name === " " ? <></> : <button className="button round-button outline-button my-1 py-1" >
                     {SingleItemDetail?.name}<i className="fa fa-close" onClick={() => clearSelectedcheckedItem(SingleItemDetail.id, props.prevCheckedItem)}></i></button>
                  } else {
                     return <></>
                  }
               }
               )
            }) : <></>}

             <button className="button round-button outline-button my-0 py-1"  onClick={ ()=> setClearAll()}>{translationUtils.getTranslation(912, "Clear All")} <i className="fa fa-close" ></i></button>

            </div>
         <hr />
      </div>  
   </div>
)} 
         <div className="srp-area">
        
            
         <div>
            <div className=" showing-top-results">

               {searchWithIn.length > 0 ? <h4>{translationUtils.getTranslation(1688, `Showing top results for`)}: {searchbox} {searchWithIn &&  searchWithIn[0]!== '' && <span>for "{searchWithIn}"</span>}</h4> 
               : <h4>{translationUtils.getTranslation(1688, `Showing top results for`)}: {searchbox}</h4>}

               
            </div>
            <hr/>
        </div>
       
        
        <div class="row">
    
        <div className="clearfix ph-search-contents__results">
        { myposts.length > 0 && myposts.map((item, index) => {
        // {items.map((item) => {
return (
           <div className="ph-browse-list-view__product">
            <a href={getProductLink(item)}  className="a-no-underline default">
                 <div className="ph-browse-list-view__content">
                    <div className="ph-browse-list-view__left">
                       <div className="ph-browse-list-view__left--img-container"><img alt="no image" src={ item.thumbnailURL_s ? item.thumbnailURL_s :
                                        process.env.PUBLIC_URL +
                                        '/assets/img//Rectangle Copy.png'
                                      } title="Category | Valves" /></div>
                       <div className="ph-browse-list-view__left--container">
                          <h2 className="ph-browse-list-view__left--title">{item.siteSubSection_ss == "Product Category" ? `${translationUtils.getTranslation(1510, "Category")} | `+ item[`title${language}_t`] : item[`title${language}_t`]}
                          {item._parkerexactmatch ? <span class="ph-flag ph-flag__noteworthy exact-match">Exact Match</span> : ''}
                          </h2>
                          <div className="ph-browse-list-view__left--short-desc js-browse-list__truncate "><span><div dangerouslySetInnerHTML={{ __html: item[`description${language}_t`] }}></div></span></div>
                       </div>
                    </div>
                    <div className="ph-browse-list-view__right">
                    <div className="ph-browse-list-view__right--literature entry">{getProductLabel(item)}<i className="far fa-chevron-right" aria-hidden="true"></i></div>
                    
                    </div>
                 </div>
              </a>
           </div>
         //)}
        // )}
        )}
             )}
                                    
         </div>
        </div>
        </div>
        </div>
      
          ) : 
          <div className="col-md-12 col-lg-12 col-xl-9 results">
          <NoResultPage />
          </div>
          }
       </div>
       </div>
         }
      

      {isProduct == 'support' && 
      <div>
   <div id="body-overlay1"></div>
   <div className="row">
  
      <div className="col-xl-3 ph-search-sidebar hide-mobile">
     
       <FilterSidebar filteTab="support" onfetchData = {props.onfetchData} 
             resultAPIData = {myposts} 
             allFacetsData = {props.allFacetsData} 
             resultInfo={props.resultInfo} 
             closeFilterSideBar={closeNav}
             prevCheckedItem = {props.prevCheckedItem}
             setCheckedfromChild = {setCheckedfromChild}
             language = {props.language}
             setClearAll = {setClearAll}
             clearInputSearchValue = {clearInputSearchValue}
             clearFilter = {props.clearFilter}
             catalogStateUpdate = {props.updtaeCatalogState}
             setSearchBox = {props.setSearchBox}
             />
      </div>
   <div className="col-md-12 col-lg-12 col-xl-9 results">
   
   {sidepanelfour &&    <div id="mySidepanel1" className="sidepanel">
     
     <div className=" top-filter-bar">
     <div className="filters_search">Filters & Search</div>
    
<div><a href="javascript:void(0)" className="closebtn " onClick={closeNav}>×</a></div>
<span className="break-line"><hr /></span>
</div>
  
  <FilterSidebar filteTab="support" onfetchData = {props.onfetchData} 
             resultAPIData = {myposts} 
             allFacetsData = {props.allFacetsData} 
             resultInfo={props.resultInfo} 
             closeFilterSideBar={closeNav}
             prevCheckedItem = {props.prevCheckedItem}
             setCheckedfromChild = {setCheckedfromChild}
             language = {props.language}
             setClearAll = {setClearAll}
             clearInputSearchValue = {clearInputSearchValue}
             clearFilter = {props.clearFilter}
             catalogStateUpdate = {props.updtaeCatalogState}
             setSearchBox = {props.setSearchBox}
             />
</div>
}
<div className="mob-all-filter">
   <hr />
   <button class="openbtn btn" id="mypanelSupport" onClick={(e)=>openNav(e)}><span className="all-filter-img"><img alt="no image" src={process.env.PUBLIC_URL +
                                        '/assets/img/Shape Copy@3x.png'
                                      } /></span><span>All Filters</span></button>
                                      <hr />
                                      </div>
{props?.resultInfo?.length > 0 && (
   <div>
      <div className="filter-list hide-mobile  show-1024">

         <span>{translationUtils.getTranslation(1153, "Filters")}:</span>  

         
         {props.allFacetsData ? Object.keys(props.allFacetsData).map((keyValue) => {
               return props?.allFacetsData[keyValue]?.map((data) => {
                  const SingleItemDetail = getSingleItemDetail(data)
                  
                  if(props.prevCheckedItem?.indexOf(SingleItemDetail?.id) !== -1){
                     
                     return SingleItemDetail?.id?.includes(getPid(supportPid)) 
                     || SingleItemDetail?.id?.includes(getPid(productPid))
                     || SingleItemDetail?.id?.includes(getPid(xrefPid))
                     || SingleItemDetail?.name === "" ? <></> : <button className="button round-button outline-button my-1 py-1">
                  {SingleItemDetail?.name}<i className="fa fa-close" onClick={ () => clearSelectedcheckedItem(SingleItemDetail.id)}></i></button>
                  } else {
                     return <></>
                  }
               }
               )
            }) : <></>}

             <button className="button round-button outline-button my-0 py-1" onClick={ ()=> setClearAll()}>{translationUtils.getTranslation(912, "Clear All")} <i className="fa fa-close" ></i></button>

         </div>
   
      <div className="hide-largescreen">
         <hr />
            <div className="filter-list">

               <span>{translationUtils.getTranslation(1153, "Filters")}:</span>  

               {props.allFacetsData ? Object.keys(props.allFacetsData).map((keyValue) => {
               return props?.allFacetsData[keyValue]?.map((data) => {
                  const SingleItemDetail = getSingleItemDetail(data)
                     if(props.prevCheckedItem?.indexOf(SingleItemDetail?.id) !== -1){
                        {console.log("getpid",getPid(supportPid))}
                        return SingleItemDetail?.id?.includes(getPid(supportPid)) 
                     || SingleItemDetail?.id?.includes(getPid(productPid))
                     || SingleItemDetail?.id?.includes(getPid(xrefPid))
                        || SingleItemDetail?.name === "" ? <></> : <button className="button round-button outline-button my-1 py-1">
                     {SingleItemDetail?.name}<i className="fa fa-close" onClick={ () => clearSelectedcheckedItem(SingleItemDetail.id)}></i></button>
                     } else {
                        return <></>
                     }
                  }
                  )
               }) : <></>}

             <button className="button round-button outline-button my-0 py-1" onClick={()=> setClearAll()}>{translationUtils.getTranslation(912, "Clear All")} <i className="fa fa-close" ></i></button>

               </div>
         <hr />
      </div>  
   </div>
)} 
   {props?.resultInfo?.length > 0 ? (   
   <div className="srp-area">
   {props?.resultInfo?.length > 0 && (
      <div>
      <div className="srp-header">
        <div className=" showing-top-results">

        {searchWithIn.length > 0 ? <h4>{translationUtils.getTranslation(1688, `Showing top results for`)}: {searchbox} {searchWithIn &&  searchWithIn[0]!== '' && <span>{translationUtils.getTranslation(705, `for`)} "{searchWithIn}"</span>}</h4> 
               : <h4>{translationUtils.getTranslation(1688, `Showing top results for`)}: {searchbox}</h4>}

        </div>
        <div className="sortby">

        <h4>{translationUtils.getTranslation(85, "Sort by")}:</h4> 

        <select className="form-select form-select-sm" onChange={(e) => props.sortBy(e.target.value)}>
     <option value="">Default</option>
     <option value="title_s asc">A-Z</option>
     <option value="title_s desc">Z-A</option>

     <option value="publishedCiDate_dt desc">{translationUtils.getTranslation(3721, "Newest")}-{translationUtils.getTranslation(3726, "Oldest")}</option>
     <option value="publishedCiDate_dt asc">{translationUtils.getTranslation(3726, "Oldest")}-{translationUtils.getTranslation(3721, "Newest")}</option>

   </select>
 
   
   </div>
   </div>
   <hr />
   </div>
   
   )}
   
   <div className="clearfix ph-search-contents__results support-list">
    {/* <span class="ph-flag ph-flag__noteworthy exact-match">Exact Match</span> */}
    { myposts.length > 0 ? myposts.map((item, index) => {
return (
   
      <div key={index} className="ph-browse-list-view__product">
      

      {item.audience_ss == "private" && item.otAssetType_s == "Static File" ?
    
 

<div className="ph-browse-list-view__content">
<a  onClick={() => {downloadPrivateAssets(item.fileName_s, item.fileType_ss, item.id, item.supportResourceType_ss
, item.supportType_ss, item.otDivDescription_s
)}} className="a-no-underline default support-wrapper" target="_blank">
               <div className="ph-browse-list-view__left">
                  
                  <div className="ph-browse-list-view__left--img-container">
                     <img alt="no image" src={ item.imageURL_s ? item.imageURL_s :
                                        process.env.PUBLIC_URL +
                                        '/assets/img//email.png'
                                      } title="SBW110 Mobile Valve | Mobile Hydraulic Systems Division Europe"/>
                                                                             
       {item.fileType_ss ? 
      <div className="ph-browse-list-view__left--files">
                        
                        <div>

         <a className="links" target="_blank"><span className="link-label"><button>{"."+item.fileType_ss}</button></span></a>
         </div>
         </div>
          : ''}
                                      </div>
                                     
                  <div className="ph-browse-list-view__left--container support-content-left">
                     <p>{item[`supportType${language}_ss`]}</p>
                     <h2 className="ph-browse-list-view__left--title">{item[`title${language}_t`]}
                     {item._parkerexactmatch ? <span class="ph-flag ph-flag__noteworthy exact-match">Exact Match</span> : ''}
                     
                     </h2>
                     <div className="createdate_publishdate">
                     {item.createCiDate_dt ? 
                     <div><span className="createdate">Created Date:</span><span>{convertDate(item.createCiDate_dt)}</span></div> : ''}
                     {item.publishedCiDate_dt ? <div><span className="publishdate">Publish Date:</span><span>{convertDate(item.publishedCiDate_dt)}</span></div> : ''}
                     
                     </div>
                   
         </div>
         </div>
         </a>
         <div className="ph-browse-list-view__right support-content-right">
            
         <div className="support-email ph-browse-list-view__right--literature entry">
            <div className="description">
             
           

            {item[`description${language}_t`] && 
               <p>{ truncateDesription(item[`description${language}_t`].toString(), 80) }</p>

            }
      {item.parkerDivisionName_ss && 

            <div>{ truncateDesription(item.parkerDivisionName_ss.toString(), 45) }</div>

      }
      {item.supportResourceType_ss && 

            <div>{ truncateDesription(item.supportResourceType_ss.toString(), 45) }</div>

      }
      
            </div>
            <div className="result-email-btn">
            <a className="button round-button outline-button my-0 py-1" onClick={() => getProductDetailForEmail(item[`title${language}_t`], item.imageURL_s ? item.imageURL_s :
                                        process.env.PUBLIC_URL +
                                        '/assets/img//email.png', 
                                        item[`description${language}_t`].toString(),
                                        item.id,
                                        item.fileType_ss, 
                                        item.supportType_ss,
                                        item.supportResourceType_ss, 
                                      )} data-toggle="modal" data-target="#exampleModal" style={{background:"none"}}><span>Email</span></a>
                                 
            </div>
            </div>
         </div>
         
         </div>
 
:


           
            <div className="ph-browse-list-view__content">
               <a  href={getProductLink(item)} className="a-no-underline default support-wrapper" target="_blank">
               <div className="ph-browse-list-view__left">
                  
                  <div className="ph-browse-list-view__left--img-container">
                     <img alt="no image" src={ item.imageURL_s ? item.imageURL_s :
                                        process.env.PUBLIC_URL +
                                        '/assets/img//email.png'
                                      } title="SBW110 Mobile Valve | Mobile Hydraulic Systems Division Europe"/>
                                                                             
       {item.fileType_ss ? 
      <div className="ph-browse-list-view__left--files">
                        
                        <div>

         <a className="links" target="_blank"><span className="link-label"><button>{"."+item.fileType_ss}</button></span></a>
         </div>
         </div>
          : ''}
                                      </div>
                                     
                  <div className="ph-browse-list-view__left--container support-content-left">
                     <p>{item[`supportType${language}_ss`]}</p>
                     <h2 className="ph-browse-list-view__left--title">{item[`title${language}_t`]}
                     {item._parkerexactmatch ? <span class="ph-flag ph-flag__noteworthy exact-match">Exact Match</span> : ''}
                     
                     </h2>
                     <div className="createdate_publishdate">
                     {item.createCiDate_dt ? 
                     <div><span className="createdate">Created Date:</span><span>{convertDate(item.createCiDate_dt)}</span></div> : ''}
                     {item.publishedCiDate_dt ? <div><span className="publishdate">Publish Date:</span><span>{convertDate(item.publishedCiDate_dt)}</span></div> : ''}
                     
                     </div>
                   
         </div>
         </div>
         </a>
         <div className="ph-browse-list-view__right support-content-right">
            
         <div className="support-email ph-browse-list-view__right--literature entry">
            <div className="description">
             
           

            {item[`description${language}_t`] && 
               <p>{ truncateDesription(item[`description${language}_t`].toString(), 60) }</p>

            }
      {item.parkerDivisionName_ss && 

            <div>{ truncateDesription(item.parkerDivisionName_ss.toString(), 45) }</div>

      }
      {item.supportResourceType_ss && 

            <div>{ truncateDesription(item.supportResourceType_ss.toString(), 45) }</div>

      }
            </div>
            <div className="result-email-btn">
            <a className="button round-button outline-button my-0 py-1" 
               onClick={() => getProductDetailForEmail(item[`title${language}_t`], item.imageURL_s ? item.imageURL_s :
                                        process.env.PUBLIC_URL +
                                        '/assets/img//email.png', 
                                        item[`description${language}_t`].toString(),
                                        item.id,
                                        item.fileType_ss, 
                                        item.supportType_ss,
                                        item.supportResourceType_ss,
                                      )} 
                                      data-toggle="modal" 
                                      data-target="#exampleModal" 
                                      style={{background:"none"}}><span>Email</span></a>
                            
            </div>
            </div>
         </div>
         
         </div>
        
    }
      </div>
  )}) : <NoResultPage />}
{showModal && (


<Modal show={show} onHide={handleClose}>
<EmailComponent 
      showModal={setShowModal}
      handleClose={handleClose}
      productDetail={selectedId}
      language={language}
    />
</Modal>

 )}
  
    {/* <div className="ph-browse-list-view__product">
         <a href="https://phtest.parker.com/us/${language.toLower()}/sporlan-normally-closed-solenoid-valve-with-check-valve" className="a-no-underline default">
           
            <div className="ph-browse-list-view__content">
               <div className="ph-browse-list-view__left">
                  <div className="ph-browse-list-view__left--img-container">
                     <img alt="no image" src="./src/containers/search/assets/image/Rectangle Copy.png" title="SBW110 Mobile Valve | Mobile Hydraulic Systems Division Europe"/></div>
                  <div className="ph-browse-list-view__left--container">
                     <p>Distributor Support</p>
                     <h2 className="ph-browse-list-view__left--title">Video</h2>
                     <div className="createdate_publishdate"><div><span><b>Created Date:</b></span><span>16 OCt</span></div><div>Publish Date:<span>16 Oct</span></div></div>
                     <div className="ph-browse-list-view__left--files">
                        <div>
         <a className="links"><span className="link-label"><button>.zip</button></span><i className="far fa-arrow-to-bottom" aria-hidden="true"></i></a></div></div></div></div>
         <div className="ph-browse-list-view__right">
            
         <div className="support-email ph-browse-list-view__right--literature entry">
            <div className="description">
            <p className="">
            In English</p>
            <h4>Corporate</h4>
            <h4>Customer sales</h4>
            </div>
            <button className="button round-button outline-button my-0 py-1" style={{background:"none"}}><span>Email</span></button></div>
         </div>
         
         </div>
         </a>
      </div> */}
         
      </div>
      </div>
   ) : <NoResultPage /> }
      </div>
         
   </div>
   </div>
   }
   {isProduct == 'xref' && 
      <div>
         <div id="body-overlay2"></div>
         <div class="row">
         <div class=" col-xl-3 ph-search-sidebar hide-mobile">
         <FilterSidebar filteTab="xref" onfetchData = {props.onfetchData} 
             resultAPIData = {myposts} 
             allFacetsData = {props.allFacetsData} 
             resultInfo={props.resultInfo} 
             closeFilterSideBar={closeNav}
             prevCheckedItem = {props.prevCheckedItem}
             setCheckedfromChild = {setCheckedfromChild}
             language = {props.language}
             setClearAll = {setClearAll}
             clearInputSearchValue = {clearInputSearchValue}
             catalogStateUpdate = {props.updtaeCatalogState}
             setSearchBox = {props.setSearchBox}
             />
             </div>
         
      
   <div className="col-md-12 col-lg-12 col-xl-9 results cross-ref">
   {sidepanelfour && 
   <div id="mySidepanel2" className="sidepanel">
     
     <div className=" top-filter-bar">
     <div className="filters_search">Filters & Search</div>
    
<div><a href="javascript:void(0)" className="closebtn " onClick={closeNav}>×</a></div>
<span className="break-line"><hr /></span>
</div>

<FilterSidebar filteTab="xref" onfetchData = {props.onfetchData} 
             resultAPIData = {myposts} 
             allFacetsData = {props.allFacetsData} 
             resultInfo={props.resultInfo} 
             closeFilterSideBar={closeNav}
             prevCheckedItem = {props.prevCheckedItem}
             setCheckedfromChild = {setCheckedfromChild}
             language = {props.language}
             setClearAll = {setClearAll}
             clearInputSearchValue = {clearInputSearchValue}
             catalogStateUpdate = {props.updtaeCatalogState}
             setSearchBox = {props.setSearchBox}
             />
</div>
}
   <div className="mob-all-filter">
      <hr />
         <button class="openbtn btn" id="mypanelXref" onClick={(e)=>openNav(e)}><span className="all-filter-img"><img alt="no image" src={process.env.PUBLIC_URL +
            '/assets/img/Shape Copy@3x.png'
         } /></span><span>All Filters</span></button>
      <hr />
   
   </div>
   {props?.resultInfo?.length > 0 && (
   <div>
      <div className="filter-list hide-mobile  show-1024">

         <span>{translationUtils.getTranslation(1153, "Filters")}:</span>  

         {props.allFacetsData ? Object.keys(props.allFacetsData).map((keyValue) => {
               return props?.allFacetsData[keyValue]?.map((data) => {
                  const SingleItemDetail = getSingleItemDetail(data)
                  if(props.prevCheckedItem?.indexOf(SingleItemDetail?.id) !== -1){
                     return SingleItemDetail?.id?.includes(getPid(supportPid)) 
                     || SingleItemDetail?.id?.includes(getPid(productPid))
                     || SingleItemDetail?.id?.includes(getPid(xrefPid))
                     || SingleItemDetail?.name === "" ? <></> : <button className="button round-button outline-button my-1 py-1">
                  {SingleItemDetail?.name}<i className="fa fa-close" onClick={() => clearSelectedcheckedItem(SingleItemDetail.id)}></i></button>
                  } else {
                     return <></>
                  }
               }
               )
            }) : <></>}

             <button className="button round-button outline-button my-0 py-1" onClick={()=> setClearAll()}>{translationUtils.getTranslation(912, "Clear All")} <i className="fa fa-close" ></i></button>

         </div>
   
      <div className="hide-largescreen">
         <hr />
            <div className="filter-list">

               <span>{translationUtils.getTranslation(1153, "Filters")}:</span>  

               {props.allFacetsData ? Object.keys(props.allFacetsData).map((keyValue) => {
               return props?.allFacetsData[keyValue]?.map((data) => {
                     const SingleItemDetail = getSingleItemDetail(data)
                     if(props.prevCheckedItem?.indexOf(SingleItemDetail?.id) !== -1){
                        return SingleItemDetail?.id?.includes(getPid(supportPid)) 
                        || SingleItemDetail?.id?.includes(getPid(productPid))
                        || SingleItemDetail?.id?.includes(getPid(xrefPid))
                        || SingleItemDetail?.name === "" ? <></> : <button className="button round-button outline-button my-1 py-1" >
                     {SingleItemDetail?.name}<i className="fa fa-close" onClick={() => clearSelectedcheckedItem(SingleItemDetail.id)}></i></button>
                     } else {
                        return <></>
                     }
                  }
                  )
               }) : <></>}

             <button className="button round-button outline-button my-0 py-1"  onClick={()=> setClearAll()}>{translationUtils.getTranslation(912, "Clear All")} <i className="fa fa-close" ></i></button>

               </div>
         <hr />
      </div>  
   </div>
)} 
   {/* <div className="srp-area">  */}
      {/* <div className={props.resultInfo.length > 0 ? "srp-area" : ""}> */}
      {props?.resultInfo?.length > 0 &&
         <div className="ph-search-selected__query showing-top-results">

            {searchWithIn.length > 0 ? <h4>{translationUtils.getTranslation(1688, `Showing top results for`)}: {searchbox} {searchWithIn &&  searchWithIn[0]!== '' && <span>{translationUtils.getTranslation(705, "for")} "{searchWithIn}"</span>}</h4> 
               : <h4>{translationUtils.getTranslation(1688, `Showing top results for`)}: {searchbox}</h4>}

         </div> 
   }
   {/* <hr/> */}
   <div className="clearfix ph-search-contents__results cross-ref">
      <CrossReference crossrefResults={props.resultInfo} language={language}/>

   {/* </div> */}
      </div>
      </div>
      </div>
      </div>
   }
</div>

    )
}