import { importUserAPICall } from '../services/getUserDetail';
import { getHost, getHostForDA, getHostForTokenMangement } from '../apiconfig';

/**
 * Get Varification URL
 * @return {string} url
 */
export function getVarificationUrl() {
  if (
    window.location.hostname.toLowerCase().indexOf('localhost') >= 0 ||
    window.location.hostname.toLowerCase().indexOf('myparkertest') >= 0
  ) {
    return `https://loginqa.parker.com/api/v1/sessions/me`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0
  ) {
    return `https://logintest.parker.com/api/v1/sessions/me`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkerstage') >= 0
  ) {
    return `https://loginstage.parker.com/api/v1/sessions/me`;
  } else if (window.location.hostname.toLowerCase().indexOf('myparker') >= 0) {
    return `https://login.parker.com/api/v1/sessions/me`;
  } else {
    //return `${window.allData.userVerificationUrl}api/v1/sessions/me`;
    return `https://login.parker.com/api/v1/sessions/me`;
  }
}

/** Get Url to get the notification data and count */
export function getNotificationUrl(contractId) {
  if (
    window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0 ||
    window.location.hostname.toLowerCase().indexOf('localhost') >= 0
  ) {
    return `https://apidev.parker.com/dev/myparker/api/application/MyParker/getNotificationData`;
  } else
    return 'https://apidev.parker.com/test/myparker/api/application/MyParker/getNotificationData';
}

/** Get Url for find the auth Id associated with that customer */
export function getCustAuth(contractId) {
  if (
    window.location.hostname.toLowerCase().indexOf('cor089') < 0 &&
    window.location.hostname.toLowerCase().indexOf('localhost') < 0
  ) {
    return `/SolrApi/solr/DistributorService/auth?ContractId=${contractId}`;
  } else
    return (
      'http://contentsearchtest.us.parker.corp/SolrApi/solr/DistributorService/auth?ContractId=' +
      contractId
    );
}

/**
 * Get Translation Url based on Ids and Locale
 * @param {string} ids //Comman Seperated Ids
 * @param {string} locale
 * @return {string} url
 */
export function getTranslationUrl() {
  return (
    getHost().split('/delegatedadmin')[0] +
    '/TranslationRestAPI/Translation/getTranslations'
  );
}
export function getHeaderFooterUrl(lang, userEmail) {
  if (window.location.hostname.toLowerCase().indexOf('localhost') < 0)
    return (
      getHost() +
      '/store/20951/headerfooter/with/orglist/v2?langCode=' +
      lang +
      '&logonId=' +
      userEmail
    );
  else
    return (
      getHost() +
      '/store/20951/headerfooter/with/orglist/v2?langCode=' +
      lang +
      '&logonId=manish.yadav@parker.com'
    );
}

export function getHeaderCatagoriesUrl(lang) {
  return (
    getHost() +
    '/store/20951/headerfooter/categorydata?langCode=' +
    lang +
    '&catalogId=33151'
  );
}

export function getApplicationTilesUrl(lang, customerId, userEmail) {
  if (customerId == null) {
    customerId = window.sellerCode;
  }
  if (
    window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0 &&
    window.location.hostname.toLowerCase().indexOf('localhost') < 0
  )
    return (
      'https://apidev.parker.com/dev/myparker/solr/MyParker/productData/select?customerId=' +
      customerId +
      '&userId=' +
      userEmail +
      '&countryId=687PDC&locale=' +
      lang +
      '&legacyUserId=&wt=json'
    );
  else
    return (
      'http://contentsearch.us.parker.corp/solr/MyParker/productData/select?customerId=' +
      customerId +
      '&userId=myparkeruser3@test.com&countryId=687PDC&locale=' +
      lang +
      '&legacyUserId=&wt=json'
    );
  //return "https://api.mocki.io/v1/ba25062e";
}

export function getSupportAssetsL1DataUrl(lang, customerId) {
  if (customerId == null) {
    customerId = window.sellerCode;
  }
  let sURL =
    'https://apidev.parker.com/dev/myparker/solr/MyParker/supportHome/select?json.nl=arrarr&wt=json';
  sURL += '&1.q=coreName_s:CategoryData&1.fq=LangCode_s:' + lang;

  sURL +=
    '&2.fq=languageCode_ss:(' +
    lang +
    ')%20AND%20(customerId_ss:' +
    customerId +
    ')';
  sURL += '&3.fq=langCode_s:' + lang;
  sURL += '&4.facet.field=siteSection' + lang + '_facet';
  sURL += '&5.fq=customerId_s:' + customerId;

  if (
    window.location.hostname.toLowerCase().indexOf('myparkerdev') < 0 &&
    window.location.hostname.toLowerCase().indexOf('localhost') < 0
  )
    return sURL;
  else return sURL;
}

export function getHeaderSelectUrl(lang, contractId) {
  return (
    'http://contentsearch.us.parker.corp/solr/MyParker/header/select?userId=myparkeruser3@test.com&locale=' +
    lang +
    '&contractId=' +
    contractId +
    '&legacyUserId='
  );
}

export function getSupportAssetResultAPI() {
  let url =
    'https://test.parker.com/myparker/query-profiles/myparker-en/select?_cookie=false&audianceType=private&authId=(1-1F82W8*+or+1-1VLUD9*+or+1-2ANHJ8*+or+1-5HCVJP*+or+1-7M71L*+or+1-7M72Y*+or+1-7M730*+or+1-7M73B*+or+1-7M73G*+or+1-7M743*+or+1-7M749*+or+1-7M74A*+or+1-7M74H*+or+1-7M74W*+or+1-7M74Y*+or+1-A3D8YU*+or+1-BG5AY9)&category=true&contentType=&countryId=687PDC&customerId=327106&echoParams=all&enoviaCustomerId=327106&fq=%7B!tag%3Dtag_siteSectionEN_fq%7DsiteSectionEN_fq:%22P-190113873%22&isUser=false&json.nl=arrarr&q=*&rows=20&sort=&start=0&tab=support&userType=&userid=MYPARKERUSER3@TEST.COM&wt=json';
  return url;
}

export function getListOfUsers(logonId, adminId) {
  return (
    getHost() +
    `/store/20951/person?storeId=20951&q=mpUsersICanAdmin&profileName=MyParker_User_List_Basic_Summary&orgList=${logonId}&adminId=${adminId}`
  );
}

export function getLocationByLogonId(logonId, location) {
  return getHost() + '/store/20951/manage/myparker_user/access_on_locations';
}

// export function getRolesByLogonId(lang, logonId) {
//   return (
//     getHost() +
//     "/myparker_user/access_on_locations/" +
//     logonId +
//     "?orgList=7333333333333348185,7333333333333348186"
//   );
// }

/*----Delegated Admin APIs----*/

export function getLoginHistoryURL(logonId) {
  return (
    getHost() +
    '/store/20951/utility/okta/user/activities?logonId=' +
    logonId +
    '&limit=100'
  );
}

export function getEditUserDetails(logonId) {
  return getHost() + '/store/20951/utility/okta/user/info?logonId=' + logonId;
}

export function changeUserStatusApi(status, logonId) {
  // return getHost() + '/store/20951/manage/myparker_user/access/edit';
  //return getHost() + '/store/20951/manage/myparker_user/edit';
  let url = '';
  if (status) {
    url = getHost() + '/store/20951/manage/myparker_user/access/deactivate';
  } else {
    url = getHost() + '/store/20951/manage/myparker_user/access/activate';
  }
  return url;
}

export function changeUserStatusApiEdit(status, logonId) {
  // return getHost() + '/store/20951/manage/myparker_user/access/edit';
  return getHost() + '/store/20951/manage/myparker_user/edit';
}

export function editRolesApiUrl(logonId, operation) {
  if (operation === 'edit')
    return getHost() + '/store/20951/manage/myparker_user/access/edit';
  if (operation === 'remove')
    return getHost() + '/store/20951/manage/myparker_user/access/remove';
}
export function getAssignRolesApi() {
  return (
    getHost() +
    '/store/20951/person?q=mpAllowedRoles&profileName=MyParker_Allowed_Roles_Summary'
  );
}
export function addUserApi() {
  return getHost() + '/store/20951/manage/myparker_user/add';
}

export function getResendEmailSetting(logonId) {
  return (
    getHost() + '/store/20951/manage/myparker_user/sendEmail?logonId=' + logonId
  );
}

export function getPasswordSetting(logonId, operation) {
  if (operation === 'reset')
    return (
      getHost() +
      '/store/20951/utility/okta/user/resetPassword?logonId=' +
      logonId
    );
  if (operation === 'temporary')
    return (
      getHost() +
      '/store/20951/utility/okta/user/temporaryPassword?userGuid=' +
      logonId
    );
}

export function importUserAPIURL(email) {
  return (
    getHost() + '/store/20951/manage/myparker_user/import?adminId=' + email
  );
}

export function getWCSTrustedTokenURL() {
  return getHost() + '/store/20951/loginidentity/oktaSession';
}

export function getWCSDefaultLanguageURL() {
  return getHostForDA() + '/users/store/20951/person/@self';
}

export function getHeaderFooterJsonTemplateURL(sessionId, langCode, cID) {

  let isSearch = false;
  if(window.location.pathname.includes('/search') || window.location.pathname.includes('/support') || window.location.pathname.includes('/help')){
    isSearch = true;
  } else {
    isSearch = false;
  }

  if (window.location.hostname.toLowerCase().indexOf('myparkerdev') >= 0) {
    return (
      'https://apidev.parker.com/dev/hf/myparker-html-header-footer?cid=' +
      cID +
      '&oktaSessionId=' +
      sessionId +
      '&langCode=' +
      langCode +
      '&supportAllLoc=true&orglist=true' +
      '&isSearchDaPage=' + isSearch
    );
    //return 'https://apidev.parker.com/test/hf/myparker-html-header-footer?cid='+cID+'&oktaSessionId='+sessionId+'&langCode='+langCode+'&supportAllLoc=true'
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkertest') >= 0 ||
    window.location.hostname.toLowerCase().indexOf('localhost') >= 0
  ) {
    return (
      'https://apidev.parker.com/test/hf/myparker-html-header-footer?cid=' +
      cID +
      '&oktaSessionId=' +
      sessionId +
      '&langCode=' +
      langCode +
      '&supportAllLoc=true&orglist=true' +
      '&isSearchDaPage=' + isSearch
    );
  } else if (
    window.location.hostname.toLowerCase().indexOf('myparkerstage') >= 0
  ) {
    return (
      'https://api.parker.com/stage/hf/myparker-html-header-footer?cid=' +
      cID +
      '&oktaSessionId=' +
      sessionId +
      '&langCode=' +
      langCode +
      '&supportAllLoc=true&orglist=true' +
      '&isSearchDaPage=' + isSearch
    );
  } else if (window.location.hostname.toLowerCase().indexOf('myparker') >= 0) {
    return (
      'https://api.parker.com/prod/hf/myparker-html-header-footer?cid=' +
      cID +
      '&oktaSessionId=' +
      sessionId +
      '&langCode=' +
      langCode +
      '&supportAllLoc=true&orglist=true' +
      '&isSearchDaPage=' + isSearch
    );
  } else {
    return (
      'https://api.parker.com/prod/hf/myparker-html-header-footer?cid=' +
      cID +
      '&oktaSessionId=' +
      sessionId +
      '&langCode=' +
      langCode +
      '&supportAllLoc=true&orglist=true' +
      '&isSearchDaPage=' + isSearch
    );
  }
}

export function getUserTokenAPIURL(action) {
  switch (action) {
    case 'list':
      return getHostForTokenMangement() + '/token/showToken';
    case 'create':
      return getHostForTokenMangement() + '/token/generateToken';
    case 'refresh':
      return getHostForTokenMangement() + '/token/updateToken';
    case 'deactivate':
      return getHostForTokenMangement() + '/token/deactivateToken';
  }
}
//Trying to create a PR

//Setting the API Calls for Roles  Based Access Management
export function getRolesBasedAccess() {
  return (
    getHostForTokenMangement() +
    '/myparker/adminapi/GetAssignedResources/EmailAddress/'
  );
}

export function setRolesBasedAccess() {
  return getHostForTokenMangement() + '/myparker/adminapi/SaveResources?';
}
//Setting the API Calls for Roles  Based Access Management
